
<h2>Manage email preferences for {{ email }}</h2>
<p>Note that disabling emails will prevent you from receiving communications from QuizTime, including questions that could count towards credit.  If you want to stop receiving questions for a specific quiz, you can un-enroll from that quiz instead.</p>
<form #emailPreferencesForm="ngForm" (ngSubmit)="emailPreferencesSubmit()">
  <h4>Receive the following types of emails:</h4>
  <div *ngFor="let group of this.emailPreferences.suppressions.suppressionGroups; index as i;">
    <div class="custom-control custom-switch unsubscribe-group-control">
      <input
        [(ngModel)]="this.emailPreferences.suppressions.suppressionGroups[i].suppressed"
        (change)="ensureGlobal($event.target.value)"
        name="{{ group.name }}"
        type="checkbox"
        class="custom-control-input"
        id="suppression-group-{{ group.id }}">
      <label class="custom-control-label" for="suppression-group-{{ group.id }}">{{ group.name }}</label>
      <small id="help-{{ group.id }}" class="form-text text-muted">{{ group.description }}</small>
    </div>
  </div>
  <hr>
  <div class="form-group form-check">
    <input [(ngModel)]="emailPreferences.suppressions.global" (change)="monitorGlobal()" name="global-suppressions" type="checkbox" class="form-check-input" id="globalSetting" >
    <label class="form-check-label" for="globalSetting">Unsubscribe from all QuizTime emails</label>
  </div>
  <hr>
  <button type="submit" class="btn btn-primary" [disabled]="!emailPreferencesForm.valid || emailPreferencesForm.pristine">Update preferences</button>
</form>
